import React from 'react';
import Dashboard from './Dashboard';
import Website from './Website';
import Organizer from './Organizer';
import Events from './Events';
import Promotions from './Promotions';
import Leads from './Leads';
import Lessons from './Lessons';
import People from './People';
import Locations from './Locations';
import Platforms from './Platforms';
import Organizations from './Organizations';
import Roles from './Roles';
import Projects from './Projects';
import Inventory from './Inventory';
import Financials from './Financials';

const CentralContent = ({ activeCentralContent }) => {
  return (
    <div className="centralcontent">
      {activeCentralContent === "Dashboard" && <Dashboard />}
      {activeCentralContent === "Website" && <Website />}
      {activeCentralContent === "Organizer" && <Organizer />}
      {activeCentralContent === "Events" && <Events />}
      {activeCentralContent === "Promotions" && <Promotions />}
      {activeCentralContent === "Leads" && <Leads />}
      {activeCentralContent === "Lessons" && <Lessons />}
      {activeCentralContent === "People" && <People />}
      {activeCentralContent === "Locations" && <Locations />}
      {activeCentralContent === "Platforms" && <Platforms />}
      {activeCentralContent === "Organizations" && <Organizations />}
      {activeCentralContent === "Roles" && <Roles />}
      {activeCentralContent === "Projects" && <Projects />}
      {activeCentralContent === "Inventory" && <Inventory />}
      {activeCentralContent === "Financials" && <Financials />}
    </div>
  );
};

export default CentralContent;