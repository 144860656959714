// TestContent.js
import React from 'react';

const Leads = () => {
  return (
    <div>
      <h1>Leads</h1>
      <p>This content is coming from the TestContent component.</p>
    </div>
  );
};

export default Leads;
