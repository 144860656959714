import React, { useState } from 'react';
import CentralNav from './CentralNav';
import CentralContent from './CentralContent';

const Home = () => {

  const [activeCentralContent, setActiveCentralContent] = useState("Organizer");

  return (
    <div className="centralops">
      <CentralNav 
        activeCentralContent={activeCentralContent} 
        setActiveCentralContent={setActiveCentralContent} 
      />
      <CentralContent activeCentralContent={activeCentralContent} />
    </div>
  );
};

export default Home;
