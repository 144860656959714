// TestContent.js
import React from 'react';

const Website = () => {
  return (
    <div className="temp-page">
      <h1>Website</h1>
      <div className="temp-outline">
        <ul>
        <li>Notifications</li>
          <ul>
              <li>New message</li>

              <li>Item Purchased</li>
              <li>Item Return Initiated</li>
              <li>Item Question</li>
              <li>New Elite Subscriber</li>
          </ul>
        <li>Homepage
          <ul>
              <li>Featured Music Player Tracks</li>
              <li>Featured Works</li>
              <li>Featured Posts</li>
              <li>Featured Sales</li>
          </ul>
        </li>
        <li>Soapbox</li>
          <ul>
              <li>Nofiications
                <ul>
                  <li>New like</li>
                  <li>New comment</li>
                  <li>Comment reported</li>
                </ul>
              </li>
          </ul>
        <li>Works</li>
        <li>Learn</li>
        <li>Shop</li>
        <li>Star Club</li>
        </ul>
      </div>
    </div>
  );
};

export default Website;
