import React from 'react';
import Organizer from './Organizer';

const OrganizerViewer = () => {
  return (
    <div className="organizerviewer">
      <Organizer />
    </div>
  );
};

export default OrganizerViewer;