import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './People.css';

const contacts = [
  { id: 1, firstName: 'John', lastName: 'Doe', email: 'john.doe@example.com', phone: '123-456-7890', roles: ['Developer', 'Designer'] },
  { id: 2, firstName: 'Jane', lastName: 'Smith', email: 'jane.smith@example.com', phone: '234-567-8901', roles: ['Manager'] },
  { id: 3, firstName: 'Alice', lastName: 'Johnson', email: 'alice.johnson@example.com', phone: '345-678-9012', roles: ['Developer', 'Tester'] },
  { id: 4, firstName: 'Bob', lastName: 'Brown', email: 'bob.brown@example.com', phone: '456-789-0123', roles: ['Designer'] },
  { id: 5, firstName: 'Emma', lastName: 'Davis', email: 'emma.davis@example.com', phone: '567-890-1234', roles: ['Tester', 'Manager'] },
];

const People = () => {
  const [sortBy, setSortBy] = useState('firstName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [displayField, setDisplayField] = useState('roles');

  const sortedContacts = [...contacts].sort((a, b) => {
    const compareValue = sortBy === 'firstName' 
      ? a[sortBy].localeCompare(b[sortBy])
      : a.lastName.localeCompare(b.lastName);
    return sortOrder === 'asc' ? compareValue : -compareValue;
  });

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleAddNewPerson = () => {
    alert('Add new person functionality would be implemented here');
  };

  const renderDisplayField = (contact) => {
    switch(displayField) {
      case 'email':
        return contact.email;
      case 'phone':
        return contact.phone;
      case 'roles':
        return contact.roles.join(', ');
      default:
        return '';
    }
  };

  return (
    <div className="contact-dashboard">
      <div className="dashboard-controls">
        <button onClick={handleAddNewPerson} className="add-person-btn">
          <FontAwesomeIcon icon={faPlus} className="icon" />
          Add New Person
        </button>
      </div>
      
      <table className="contact-table">
        <thead>
          <tr>
            <th>
                <div className="sort-controls">
                    <select
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        className="sort-select"
                    >
                        <option value="firstName">First Name</option>
                        <option value="lastName">Last Name</option>
                    </select>
                    <button onClick={toggleSortOrder} className="sort-order-btn">
                        <FontAwesomeIcon icon={sortOrder === 'asc' ? faChevronUp : faChevronDown} />
                    </button>
                </div>
            </th>
            <th>
              <select
                value={displayField}
                onChange={(e) => setDisplayField(e.target.value)}
                className="display-field-select"
              >
                <option value="roles">Role(s)</option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedContacts.map((contact, index) => (
            <tr key={contact.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>
                {sortBy === 'firstName'
                  ? `${contact.firstName} ${contact.lastName}`
                  : `${contact.lastName}, ${contact.firstName}`}
              </td>
              <td>{renderDisplayField(contact)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default People;