import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import 'swiper/css';
import 'swiper/css/mousewheel';
import 'swiper/css/keyboard';

const Calendar = ({ selectedView, onSelectedViewChange }) => {
  const swiperRef = useRef(null);
  const [monthOffsets, setMonthOffsets] = useState([-1, 0, 1]);
  const [activeIndex, setActiveIndex] = useState(1);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;
    if (swiper) {
      swiper.on('slideChange', () => {
        const newIndex = swiper.realIndex;
        setActiveIndex(newIndex);
        setMonthOffsets(prevOffsets => {
          const newOffsets = [...prevOffsets];
          const prevSlideIndex = (newIndex + 2) % 3;
          const nextSlideIndex = (newIndex + 1) % 3;

          newOffsets[prevSlideIndex] = newOffsets[newIndex] - 1;
          newOffsets[nextSlideIndex] = newOffsets[newIndex] + 1;

          return newOffsets;
        });
      });
    }
  }, []);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;
    if (swiper && selectedView.type === 'cell') {
      const selectedDate = selectedView.date;
      const selectedYear = selectedDate.getFullYear();
      const selectedMonth = selectedDate.getMonth();
      
      const currentMonthData = getMonthData(monthOffsets[activeIndex]);
      const currentYear = currentMonthData.year;
      const currentMonth = currentMonthData.month;

      if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth)) {
        swiper.slideNext();
      } else if (selectedYear < currentYear || (selectedYear === currentYear && selectedMonth < currentMonth)) {
        swiper.slidePrev();
      }
    }
  }, [selectedView]);

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const getMonthData = (monthOffset) => {
    const date = new Date();
    date.setMonth(date.getMonth() + monthOffset);
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = firstDay.getDay();

    const prevMonthDate = new Date(year, month - 1, 1);
    const lastDayPrevMonth = new Date(prevMonthDate.getFullYear(), prevMonthDate.getMonth() + 1, 0).getDate();

    return { year, month, daysInMonth, startingDay, lastDayPrevMonth };
  };

  const renderMonth = (monthOffset) => {
    const { year, month, daysInMonth, startingDay, lastDayPrevMonth } = getMonthData(monthOffset);
    const totalDays = startingDay + daysInMonth;
    const numberOfWeeks = Math.ceil(totalDays / 7);
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDate = today.getDate();

    return (
      <div className="monthGrid" style={{ gridTemplateRows: `repeat(${numberOfWeeks}, 1fr)` }}>
        {Array(numberOfWeeks * 7).fill(null).map((_, cellIndex) => {
          const dayNumber = cellIndex - startingDay + 1;
          const isPrevMonthDay = dayNumber <= 0;
          const isNextMonthDay = dayNumber > daysInMonth;
          let displayDay;
          let displayMonth = month;
          let displayYear = year;

          if (isPrevMonthDay) {
            displayDay = lastDayPrevMonth + dayNumber;
            displayMonth = month - 1;
          } else if (isNextMonthDay) {
            displayDay = dayNumber - daysInMonth;
            displayMonth = month + 1;
          } else {
            displayDay = dayNumber;
          }

          if (displayMonth < 0) {
            displayMonth = 11;
            displayYear -= 1;
          } else if (displayMonth > 11) {
            displayMonth = 0;
            displayYear += 1;
          }

          const isGreyedOut = isPrevMonthDay || isNextMonthDay;
          const isCurrentDay = displayYear === currentYear && displayMonth === currentMonth && displayDay === currentDate;
          const isPreviousDay = new Date(displayYear, displayMonth, displayDay) < today && !isCurrentDay;
          const isSelected = selectedView.type === 'cell' && 
                             displayYear === selectedView.date.getFullYear() && 
                             displayMonth === selectedView.date.getMonth() && 
                             displayDay === selectedView.date.getDate();

          return (
              <div 
                key={cellIndex} 
                className={`cell ${isGreyedOut ? 'outsideMonth' : 'validDay'} ${isPreviousDay ? 'previousDay' : ''} ${isSelected ? 'selected' : ''}`}
                onClick={() => {
                  console.log('Selected View:', { type: 'cell', date: new Date(displayYear, displayMonth, displayDay) });
                  onSelectedViewChange({ type: 'cell', date: new Date(displayYear, displayMonth, displayDay) });
                }}
              >
              <div className="cell-heading">
                <div className="calendar-cell-tallies"></div>
                <div className={`calendar-cell-date-number ${isCurrentDay ? 'current' : ''}`}>{displayDay}</div>
              </div>
              <div className="cell-content"></div>
            </div>
          );
        })}
      </div>
    );
  };

  const slides = monthOffsets.map((offset, index) => (
    <SwiperSlide key={index} style={{ height: '100%', width: '100%' }}>
      {renderMonth(offset)}
    </SwiperSlide>
  ));

  const currentMonthData = getMonthData(monthOffsets[activeIndex]);
  const currentMonthName = new Date(currentMonthData.year, currentMonthData.month).toLocaleString('default', { month: 'long' });

  const handleCaretUpClick = () => {
    const swiper = swiperRef.current.swiper;
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleCaretDownClick = () => {
    const swiper = swiperRef.current.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };

return (
  <div className="calendar-component">
    <div className="header-container">
      <div className="header">
        <div className="nav-month-caret" onClick={handleCaretUpClick}><FontAwesomeIcon icon={faCaretUp} /></div>
        <div 
          className={`header-selection ${selectedView.type === 'header' ? 'selected' : ''}`}
          onClick={() => onSelectedViewChange({ type: 'header', date: new Date(currentMonthData.year, currentMonthData.month, 1) })}
        >
          <span className="primary">{currentMonthName}</span><span className="secondary">{currentMonthData.year}</span>
        </div>
        <div className="nav-month-caret" onClick={handleCaretDownClick}><FontAwesomeIcon icon={faCaretDown} /></div>
      </div>
      <div className="weekDays">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="day">
            {day}
          </div>
        ))}
      </div>
    </div>
    <Swiper
      ref={swiperRef}
      direction="vertical"
      slidesPerView={1}
      spaceBetween={1}
      initialSlide={1}
      loop={true}
      keyboard={{
        enabled: true,
        onlyInViewport: false,
      }}
      mousewheel={{
        forceToAxis: true,
        releaseOnEdges: true,
        sensitivity: 0.1,
        thresholdDelta: 50,
        thresholdTime: 400,
      }}
      modules={[Mousewheel, Keyboard]}
      navigation={true}
      style={{ flex: '1', width: '100%' }}
      tabIndex={0}
    >
      {slides}
    </Swiper>
  </div>
);
};

export default Calendar;
