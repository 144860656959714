// TestContent.js
import React from 'react';

const Locations = () => {
  return (
    <div>
      <h1>Locations</h1>
      <p>This content is coming from the TestContent component.</p>
    </div>
  );
};

export default Locations;
