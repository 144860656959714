import React from 'react';

const HanchLogoGradientSVG = `

<svg version="1.1" baseProfile="basic" id="Layer_1"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 34.1 40.7"
	 xml:space="preserve">
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="17" y1="1.3" x2="17" y2="40.4" gradientTransform="matrix(1 0 0 -1 0 42)">
	<stop  offset="0" style="stop-color:#FFF33B"/>
	<stop  offset="0" style="stop-color:#FEE62D"/>
	<stop  offset="0.1" style="stop-color:#FDD51B"/>
	<stop  offset="0.2" style="stop-color:#FDCA0F"/>
	<stop  offset="0.3" style="stop-color:#FDC70C"/>
	<stop  offset="0.7" style="stop-color:#F3903F"/>
	<stop  offset="0.9" style="stop-color:#ED683C"/>
	<stop  offset="1" style="stop-color:#E93E3A"/>
</linearGradient>
<path fill="url(#SVGID_1_)" d="M33.5,35.9c-0.5,0.9-1.1,1.6-1.8,2.1s-1.6,1-2.6,1.6l-1.2,0.6c-0.6,0.3-1.2,0.5-1.7,0.5
	s-0.8,0-1.2-0.2c-0.4-0.1-1-0.4-1.8-0.7l-1-0.4c-0.8-0.4-1.7-0.6-2.7-0.6s-1.1,0-1.6,0.2c-0.5,0.1-0.9,0.2-1.2,0.3
	c-0.3,0.1-0.5,0.2-0.6,0.2c0.6-0.8,1.2-1.7,1.9-2.8V22.9c-0.2-0.5-0.8-1.2-1.7-2.2c-0.9-1-1.5-1.6-1.9-1.8c0,0.6,0,1.1,0,1.6v12.2
	c0,1.2-0.2,2.2-0.6,2.8c-0.4,0.7-0.9,1.2-1.6,1.5c-0.7,0.3-1.6,0.6-2.7,0.9c0.9-0.8,1.3-3.6,1.3-8.4s0-3.4-0.1-5
	c0-1.6-0.2-2.6-0.2-3.1c-0.3,0-0.9,0-1.5,0c-1.5,0-2.8,0.3-4,1c-1.1,0.6-2,1.4-2.6,2.4s-0.9,2-0.9,3.1s0.2,1.7,0.6,2.4
	c0.4,0.7,1,1.3,1.8,1.6c0.8,0.4,1.5,0.6,2.2,0.6s1,0,1.4-0.3c0.4-0.2,0.7-0.4,0.9-0.6s0.3-0.3,0.3-0.4c-0.2,0.4-0.5,0.8-1.2,1.1
	c-0.6,0.3-1.4,0.4-2.2,0.4S3.8,32.5,3,32.2c-0.7-0.3-1.3-0.8-1.8-1.6c-0.9-1.3-1.3-2.9-1.3-4.9s0.3-2.8,0.8-4.1
	c0.5-1.3,1.3-2.3,2.3-3c0.7-0.4,1.7-0.7,2.8-0.9c1.2-0.1,2.7-0.2,4.5-0.2V9.2H5c-1,0-1.8,0.4-2.4,1.1C2,11,1.7,11.8,1.7,12.8
	s0.3,1.7,0.8,2.3c-0.6-0.2-1.2-0.7-1.6-1.6c-0.4-0.8-0.6-1.7-0.6-2.8c0-1.6,0.4-2.9,1.3-4.1s2.1-1.7,3.9-1.7s3.2-0.1,4.8-0.4
	c1.5-0.2,3.3-0.6,5.3-1C16.3,3.3,17,3.2,17.8,3V2h0.4v1l0,0l0,0v13.8c0.2,0.4,0.6,1,1.3,1.6c0.7,0.6,1.3,1.1,1.7,1.3
	c-0.6,0.2-1.3,0.6-1.9,1s-1,1.2-1.1,2.1v13.1c1.1-1.7,2-3.5,2.8-5.2s1.2-3.2,1.2-4.3V8.1c0-1-0.6-1.4-1.9-1.4s-0.5,0-0.6,0
	c-0.2,0-0.3,0-0.3,0l8.2-5.1c0,0-0.2,0.3-0.4,0.6C27,2.5,26.8,3,26.6,3.6s-0.3,1.3-0.3,2v16.8c0,1,0.6,1.5,1.7,1.5s0.4,0,0.6,0
	s0.3,0,0.3,0l-2.6,2.8l-6.9,8.6c0.9-0.7,1.8-1,2.8-1s1.2,0.1,1.8,0.3c0.6,0.2,1.4,0.5,2.4,1l1.9,0.8c0.7,0.3,1.4,0.4,1.9,0.4
	c1.1,0,1.9-0.4,2.5-1.2s0.9-1.7,0.9-2.8s-0.2-1.4-0.5-1.7s-0.7-0.6-1.2-0.8c-0.5-0.2-0.8-0.4-0.9-0.5c-0.2-0.2-0.1-0.3,0.1-0.6
	c0.2-0.2,0.6-0.3,0.9-0.3c0.5,0,1,0.3,1.4,0.8c0.4,0.5,0.6,1.4,0.7,2.7c0,1.3-0.2,2.5-0.7,3.4L33.5,35.9z M17.8,16.7V3.2
	c-1.1,0.5-2,1.5-2.6,3s-0.9,3.2-0.9,5.2V19C15.5,19,16.7,18.2,17.8,16.7z"/>
</svg>
`;

const HGradient = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: HanchLogoGradientSVG }} />
  );
};

export default HGradient;
