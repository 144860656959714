import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faClipboardList,
  faUserFriends,
  faBox,
  faBuilding,
  faHandshake,
  faUserTag,
  faProjectDiagram,
  faChartLine,
  faBroadcastTower,
  faBullhorn,
  faChalkboardTeacher,
  faEnvelope,
  faShareNodes,
  faRocket,
} from '@fortawesome/free-solid-svg-icons';
import {
  faLightsHoliday,
  faGlobe
} from '@fortawesome/pro-solid-svg-icons';
import './App.css';
import HanchLogoGradient from './images/HanchLogoGradient';
import HGradient from './images/HGradient';

const navItems = [
  { name: 'Organizer', path: '/organizer', icon: faClipboardList, badge: 0 },
  { name: 'Website', path: '/website', icon: faGlobe, badge: 5 },
  { name: 'Events', path: '/events', icon: faCalendarAlt, badge: 2, important: true },
  { name: 'Promotions', path: '/promotions', icon: faBullhorn, badge: 0 },
  { name: 'Leads', path: '/leads', icon: faHandshake, badge: 10 },
  { name: 'Email List', path: '/emaillist', icon: faEnvelope, badge: 100 },
  { name: 'Socials', path: '/socials', icon: faShareNodes, badge: 3 },
  { name: 'Lessons', path: '/lessons', icon: faChalkboardTeacher, badge: 0 },
  { type: 'divider' },
  { name: 'Projects', path: '/projects', icon: faRocket, badge: 1 },
  { name: 'People', path: '/people', icon: faUserFriends, badge: 0 },
  { name: 'Roles', path: '/roles', icon: faUserTag, badge: 0 },
  { name: 'Organizations', path: '/organizations', icon: faProjectDiagram, badge: 4 },
  { name: 'Regular Events', path: '/titled-events', icon: faLightsHoliday, badge: 0 },
  { name: 'Locations', path: '/locations', icon: faBuilding, badge: 0 },
  { name: 'Platforms', path: '/platforms', icon: faBroadcastTower, badge: 2 },
  { type: 'divider' },
  { name: 'Inventory', path: '/inventory', icon: faBox, badge: 7 },
  { name: 'Financials', path: '/financials', icon: faChartLine, badge: 1, important: true },
];

const CentralNav = ({ activeCentralContent, setActiveCentralContent }) => {
  const [slimNav, setSlimNav] = useState(false);
  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);

  const handleLogoClick = () => setSlimNav(true);
  const handleHLogoClick = () => setSlimNav(false);

  const handleMobileNavClick = () => setIsMobileNavExpanded(!isMobileNavExpanded);

  const handleItemClick = (itemName) => {
    setActiveCentralContent(itemName);
    setIsMobileNavExpanded(false);
  };

  const renderBadge = (badge, important) => {
    if (badge === 0 || badge === null) return null;
    
    const displayBadge = badge > 99 ? '99+' : badge;
    const badgeClass = important ? 'navlist-badge important' : 'navlist-badge';
    
    return <div className={badgeClass}>{displayBadge}</div>;
  };

  const activeItem = navItems.find(item => item.name === activeCentralContent);

  return (
    <div className={`centralnav ${slimNav ? 'slim' : ''}`}>
      <div className={`mobile-navselect ${isMobileNavExpanded ? 'expanded' : ''}`}>
        {activeItem && (
          <div className="navlist-item button" onClick={handleMobileNavClick}>
            <div className="navlist-link">
              <div className="navlist-icon">
                <FontAwesomeIcon icon={activeItem.icon} />
              </div>
              <div className="navlist-label">{activeItem.name}</div>
            </div>
            {renderBadge(activeItem.badge, activeItem.important)}
          </div>
        )}
        {isMobileNavExpanded && (
          <div className="mobile-navlist">
            {navItems.map((item, index) => 
              item.type === 'divider' ? (
                <div key={index} className="navlist-divider"></div>
              ) : (
                <div 
                  key={item.path} 
                  className={`navlist-item ${activeCentralContent === item.name ? 'active' : ''}`} 
                  onClick={() => handleItemClick(item.name)}
                >
                  <div className="navlist-link">
                    <div className="navlist-icon">
                      <FontAwesomeIcon icon={item.icon} />
                    </div>
                    <div className="navlist-label">{item.name}</div>
                  </div>
                  {renderBadge(item.badge, item.important)}
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div className="desktop-navlist">
        <div className="navlist-header">
          {!slimNav && <div className="navlist-logo" onClick={handleLogoClick}><HanchLogoGradient /></div>}
          {slimNav && <div className="navlist-hlogo" onClick={handleHLogoClick}><HGradient /></div>}
          {!slimNav && <div className="navlist-title">CENTRAL<br />OPERATIONS</div>}
        </div>
        <div className="navlist-divider"></div>
        {navItems.map((item, index) => 
          item.type === 'divider' ? (
            <div key={index} className="navlist-divider"></div>
          ) : (
            <div 
              key={item.path} 
              className={`navlist-item ${activeCentralContent === item.name ? 'active' : ''}`} 
              onClick={() => setActiveCentralContent(item.name)}
            >
              <div className="navlist-link">
                <div className="navlist-icon">
                  <FontAwesomeIcon icon={item.icon} />
                </div>
                {!slimNav && <div className="navlist-label">{item.name}</div>}
              </div>
              {renderBadge(item.badge, item.important)}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CentralNav;