import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import TestContent from './TestContent'; // Import the TestContent component
import { Link } from 'react-router-dom';
import './App.css';

const Dashboard = () => {
  const {logout } = useAuth();
  const [content, setContent] = useState(null);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
      alert('Failed to log out. Please try again later.');
    }
  };

  return (
    <div className="page-content">
      <h1>Dashboard</h1>
        <div className="temp-page">
        <div className="temp-outline">
            <ul>
            <li>Notifications
                <ul>
                  
                </ul>
            </li>
            <li>Todo</li>
                <ul>
                    <li>Organizer (today)</li>
                    <li>Post reminder</li>
                    <li>Event completion reminder</li>
                    <li></li>
                </ul>
            <li>Performance</li>
              <ul>
                <li>Active promotions</li>
                <li>Latest website posts</li>
              </ul>
            </ul>
        </div>
        </div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Dashboard;
