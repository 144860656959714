import React, { useEffect, useRef, useCallback } from 'react';

const AutoSizingTextarea = ({ value, onChange, index, dayIndex, autoFocus, onBackspace }) => {
  const textareaRef = useRef(null);
  const spanRef = useRef(null);

  const updateSize = useCallback(() => {
    if (spanRef.current && textareaRef.current) {
      spanRef.current.textContent = textareaRef.current.value + '\n';
      const scrollHeight = spanRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    updateSize();
    const resizeObserver = new ResizeObserver(updateSize);
    if (textareaRef.current) {
      resizeObserver.observe(textareaRef.current);
      if (autoFocus) {
        textareaRef.current.focus();
      }
    }
    return () => resizeObserver.disconnect();
  }, [updateSize, autoFocus]);

  useEffect(() => {
    updateSize();
  }, [value, updateSize]);

  const handleInput = (e) => {
    onChange(dayIndex, index, e.target.value);
    updateSize();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && value === '') {
      event.preventDefault(); // Prevent the default backspace action
      onBackspace(dayIndex, index);
    }
  };

  const handleBlur = () => {
    if (value === '') {
      onBackspace(dayIndex, index);
    }
  };

  return (
    <div className="relative-parent">
      <span ref={spanRef} className="size-calibration" aria-hidden="true"></span>
      <textarea
        ref={textareaRef}
        value={value}
        onChange={handleInput}
        onInput={updateSize}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        className="autosized-input"
        rows="1"
      />
    </div>
  );
};

export default AutoSizingTextarea;