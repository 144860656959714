import React, { useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGripLines } from '@fortawesome/free-solid-svg-icons';
import AutoSizingTextarea from './AutoSizingTextarea';

const DraggableList = ({ items, onItemsChange, identifier }) => {
  const [focusedItemIndex, setFocusedItemIndex] = useState(null);
  const containerRef = useRef(null);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    onItemsChange(newItems);
  };

  const addItem = () => {
    const newItems = [...items, ''];
    onItemsChange(newItems);
    setFocusedItemIndex(newItems.length - 1);
  };

  const adjustDragCoordinates = (style, snapshot) => {
    if (!snapshot.isDragging) return style;

    const containerRect = containerRef.current.getBoundingClientRect();
    const adjustedX = parseFloat(style.left) - containerRect.left;
    const adjustedY = parseFloat(style.top) - containerRect.top;

    return {
      ...style,
      left: `${adjustedX}px`,
      top: `${adjustedY}px`,
    };
  };

  const handleInputChange = (dayIndex, itemIndex, value) => {
    const newItems = [...items];
    newItems[itemIndex] = value;
    onItemsChange(newItems);
  };

  const handleBackspace = (dayIndex, itemIndex) => {
    if (items[itemIndex] === '') {
      const newItems = [...items];
      newItems.splice(itemIndex, 1);
      onItemsChange(newItems);
    }
  };

  return (
    <div className="draggable-list" ref={containerRef}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`droppable-${identifier}`}>
          {(provided) => (
            <div className="item-container" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, itemIndex) => (
                <Draggable key={itemIndex} draggableId={`item-${identifier}-${itemIndex}`} index={itemIndex}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="item swiper-no-swiping"
                      style={adjustDragCoordinates(provided.draggableProps.style, snapshot)}
                    >
                      <AutoSizingTextarea
                        value={item}
                        onChange={handleInputChange}
                        dayIndex={0} // Assuming a single day for this example
                        index={itemIndex}
                        autoFocus={focusedItemIndex === itemIndex}
                        onBackspace={handleBackspace}
                      />
                      <div className="secondary">
                        <FontAwesomeIcon icon={faGripLines} />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="list-plus-button" onClick={addItem}>
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  );
};

export default DraggableList;