import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';


// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyCaeJymgOLj7Fq0PWRyUF-zn9edLU_D1rA",
  authDomain: "auth.hanch.net",
  projectId: "hanch-net",
  storageBucket: "hanch-net.appspot.com",
  messagingSenderId: "604170388779",
  appId: "1:604170388779:web:e97959295725b2d1b8716e",
  measurementId: "G-MW4LQ2LK1E"
});

// Initialize Firebase Authentication
const auth = getAuth();

// Initialize Firestore instances with specific database IDs
const dbHanchUsers = getFirestore(app, 'hanchusers');
const dbOperations = getFirestore(app, 'operations');

const functions = getFunctions(app);

export { auth, dbHanchUsers, dbOperations, functions };