import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSun, faCalendar, faGlobe, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Calendar from './Calendar';
import ItemList from './ItemList';

const Organizer = () => {
  const [selectedViewMode, setSelectedViewMode] = useState('calendar');
  const [isWideLayout, setIsWideLayout] = useState(window.innerWidth / window.innerHeight >= 3/4);
  const [calendarVisible, setCalendarVisible] = useState(true);
  const [selectedView, setSelectedView] = useState(() => {
    const now = new Date();
    return {
      type: 'cell',
      day: now.getDate(),
      date: now,
      month: now.getMonth(),
      year: now.getFullYear()
    };
  });

  useEffect(() => {
    const handleResize = () => {
      setIsWideLayout(window.innerWidth / window.innerHeight >= 3/4);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderContent = () => {
    switch (selectedViewMode) {
      case 'list':
        return <div className="ContentDetails">
              <ItemList 
                selectedView={selectedView} 
                onSelectedViewChange={setSelectedView} 
              />
        </div>;
      case 'calendar':
        return (
          <div className="ContentSplit">
            <div className="ContentNav">
              <Calendar 
                selectedView={selectedView} 
                onSelectedViewChange={setSelectedView} 
              />
            </div>
            <div className="ContentResize"></div>
            <div className="ContentDetails">
              <ItemList 
                selectedView={selectedView} 
                onSelectedViewChange={setSelectedView} 
                calendarVisible={calendarVisible} 
              />
            </div>
          </div>
        );
      case 'globe':
        return (
          <div className="ContentSplit">
            <div className="ContentNav">Year</div>
            <div className="ContentResize"></div>
            <div className="ContentDetails">
              <ItemList 
                selectedView={selectedView} 
                onSelectedViewChange={setSelectedView} 
              /></div>
          </div>
        );
      case 'clock':
        return <div className="ContentDetails">
              <ItemList 
                selectedView={selectedView} 
                onSelectedViewChange={setSelectedView} 
              />
        </div>;
      default:
        return null;
    }
  };

  return (
    <div className={`Organizer ${isWideLayout ? 'wide' : 'narrow'}`}>
      <div className="OrganizerNav">
        <FontAwesomeIcon icon={faList} onClick={() => setSelectedViewMode('list')} />
        <FontAwesomeIcon icon={faSun} onClick={() => setSelectedViewMode('calendar')} />
        <FontAwesomeIcon icon={faCalendar} onClick={() => setSelectedViewMode('calendar')} />
        <FontAwesomeIcon icon={faGlobe} onClick={() => setSelectedViewMode('globe')} />
        <div>----</div>
        <FontAwesomeIcon icon={faLayerGroup} onClick={() => setSelectedViewMode('clock')} />
      </div>
      <div className="OrganizerContent">
        {renderContent()}
      </div>
    </div>
  );
};

export default Organizer;