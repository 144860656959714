import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard } from 'swiper/modules';
import DraggableList from './components/DraggableList.js';
import 'swiper/css';
import 'swiper/css/mousewheel';
import 'swiper/css/keyboard';

const ItemList = ({ selectedView, onSelectedViewChange, calendarVisible }) => {
  const [views, setViews] = useState(Array(3).fill().map(() => []));
  const swiperRef = useRef(null);

  const [viewOffsets, setViewOffsets] = useState([-1, 0, 1]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [lastSelectedDate, setLastSelectedDate] = useState(null);

  // Continually rotates 3 slides forward or backwards in a loop

  const handleSlideChange = useCallback(() => {
    const swiper = swiperRef.current.swiper;
    if (swiper) {
      const newIndex = swiper.realIndex;
      setActiveIndex(newIndex);
      setViewOffsets(prevOffsets => {
        const newOffsets = [...prevOffsets];
        const prevSlideIndex = (newIndex + 2) % 3;
        const nextSlideIndex = (newIndex + 1) % 3;

        newOffsets[prevSlideIndex] = newOffsets[newIndex] - 1;
        newOffsets[nextSlideIndex] = newOffsets[newIndex] + 1;

        return newOffsets;
      });
    }
  }, []);

  // Calculate offsets

  useEffect(() => {
    if (selectedView && selectedView.date && (!lastSelectedDate || +selectedView.date !== +lastSelectedDate)) {
      const swiper = swiperRef.current.swiper;
      if (swiper) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + viewOffsets[activeIndex]);
        console.log("Current Date:", currentDate);

        const daysDiff = Math.round((selectedView.date - currentDate) / (1000 * 60 * 60 * 24));
        console.log("Days Difference:", daysDiff);

        if (daysDiff !== 0) {
          const newOffsets = [...viewOffsets];
          const targetOffset = viewOffsets[activeIndex] + daysDiff;
          const nextIndex = (activeIndex + 1) % 3;
          const prevIndex = (activeIndex + 2) % 3;

          if (daysDiff > 0) {
            newOffsets[activeIndex] = targetOffset;
            newOffsets[nextIndex] = targetOffset + 1;
            newOffsets[prevIndex] = targetOffset + 1;
            console.log("New Offsets (slideNext):", newOffsets);
            setViewOffsets(newOffsets);
            setTimeout(() => swiper.slideNext(), 0);
          } else { 
            newOffsets[prevIndex] = targetOffset + 1;
            newOffsets[nextIndex] = targetOffset + 1;
            newOffsets[activeIndex] = targetOffset + 2;
            console.log("New Offsets (slidePrev):", newOffsets);
            setViewOffsets(newOffsets);
            setTimeout(() => swiper.slidePrev(), 0);
          }
        }
        setLastSelectedDate(selectedView.date);
      }
    }
  }, [selectedView, activeIndex, viewOffsets, lastSelectedDate]);


    useEffect(() => {
    const swiper = swiperRef.current.swiper;
    if (swiper) {
      swiper.on('slideChange', handleSlideChange);

      return () => {
        swiper.off('slideChange', handleSlideChange);
      };
    }
  }, [handleSlideChange]);

  useEffect(() => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + viewOffsets[activeIndex]);
    onSelectedViewChange({
      type: 'cell',
      day: newDate.getDate(),
      date: newDate,
      month: newDate.getMonth(),
      year: newDate.getFullYear(),
    });
  }, [activeIndex, viewOffsets, onSelectedViewChange]);

  const handleItemsChange = (viewIndex, newItems) => {
    const newViews = [...views];
    newViews[viewIndex] = newItems;
    setViews(newViews);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'TH';
    switch (day % 10) {
      case 1:
        return 'ST';
      case 2:
        return 'ND';
      case 3:
        return 'RD';
      default:
        return 'TH';
    }
  };

  const formatDateTitle = (date) => {
    const dayOfWeek = date.toLocaleString('default', { weekday: 'long' });
    const month = date.toLocaleString('default', { month: 'short' });
    const dayOfMonth = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(dayOfMonth);
    return { dayOfWeek, month, dayOfMonth, ordinalSuffix };
  };

  const formatMonthTitle = (month, year) => {
    const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });
    return `${monthName} ${year}`;
  };

  const renderView = (viewOffset, index) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + viewOffset);
    const { dayOfWeek, month, dayOfMonth, ordinalSuffix } = formatDateTitle(currentDate);

    return (
      <div className="list-page">
        <div className="header-container">
          <div className="header">
            <div className="header-title">
              {selectedView.type === 'month' ? (
                <div className="primary">{formatMonthTitle(selectedView.month, selectedView.year)}</div>
              ) : (
                <>
                  <div className="primary">{dayOfWeek},</div>
                  <div className="secondary">
                    {calendarVisible ? dayOfMonth : `${month} ${dayOfMonth}`}
                    <sup className="text-regular">{ordinalSuffix}</sup>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="list-content">
          <DraggableList
            items={views[index]}
            onItemsChange={(newItems) => handleItemsChange(index, newItems)}
            identifier={`view-${viewOffset}`}
          />
        </div>
      </div>
    );
  };

  const slides = viewOffsets.map((offset, index) => (
    <SwiperSlide key={index} style={{ height: '100%', width: '100%' }}>
      {renderView(offset, index)}
    </SwiperSlide>
  ));

  return (
    <div className="ItemList">
      <Swiper
        ref={swiperRef}
        modules={[Mousewheel, Keyboard]}
        loop={true}
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
          sensitivity: 0.1,
          thresholdDelta: 50,
          thresholdTime: 400,
        }}
        keyboard={{
          enabled: true,
        }}
        className="swiper-tasks"
        direction={'horizontal'}
        scrollbar={{ draggable: true }}
        initialSlide={1}
      >
        {slides}
      </Swiper>
    </div>
  );
};

export default ItemList;
