// TestContent.js
import React from 'react';

const Events = () => {
  return (
    <div>
      <h1>Events</h1>
      <p>This content is coming from the TestContent component.</p>
    </div>
  );
};

export default Events;
