// TestContent.js
import React from 'react';

const Platforms = () => {
  return (
    <div>
      <h1>Platforms</h1>
      <li>Test</li>
    </div>
  );
};

export default Platforms;
